import { useEffect } from 'react';

const Index = () => {
  useEffect(() => {
    window.location.href = '/app/assistant/board';
  });

  return <></>;
};

export default Index;
